<template>
  <div
    v-if="company.id"
    class="overflow-scroll rounded-lg border border-slate-300"
  >
    <div class="flex gap-1 overflow-x-auto border-b border-slate-300 p-2">
      <div
        v-for="(item, index) in menuItems"
        :key="item.name"
        class="flex items-center"
      >
        <div
          v-if="item.name.includes('divider')"
          class="mx-2 h-7 border-l border-slate-300"
        />
        <div
          v-else
          class="cursor-pointer rounded-md p-1"
          :class="{
            'text-white':
              company.primaryText == 'light' &&
              (editor.isActive(
                item.stateName,
                item.level ? { level: item.level } : {}
              ) ||
                hovers[index]),
            'text-slate-700':
              company.primaryText == 'dark' &&
              (editor.isActive(
                item.stateName,
                item.level ? { level: item.level } : {}
              ) ||
                hovers[index]),
            'text-slate-600':
              !editor.isActive(
                item.stateName,
                item.level ? { level: item.level } : {}
              ) && !hovers[index],
          }"
          :style="{
            backgroundColor: hovers[index]
              ? company.primary[600]
              : editor.isActive(
                  item.stateName,
                  item.level ? { level: item.level } : {}
                )
              ? company.primary[500]
              : 'white',
          }"
          @click="item.command"
          @mouseover="hovers[index] = true"
          @mouseleave="hovers[index] = false"
        >
          <component :is="item.name" class="h-6 w-6" />
        </div>
      </div>
    </div>
    <div class="p-2">
      <editor-content
        class="rounded-md border border-slate-300"
        :editor="editor"
      />
    </div>
    <div></div>
  </div>
</template>

<script>
import UnderlineExtension from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/vue-3";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import { useStore } from "vuex";
import Blockquote from "./icons/Blockquote.vue";
import Bold from "./icons/Bold.vue";
import BulletList from "./icons/BulletList.vue";
import ClearNodes from "./icons/ClearNodes.vue";
import Code from "./icons/Code.vue";
import HardBreak from "./icons/HardBreak.vue";
import Heading1 from "./icons/Heading1.vue";
import Heading2 from "./icons/Heading2.vue";
import Heading3 from "./icons/Heading3.vue";
import HorizontalRule from "./icons/HorizontalRule.vue";
import Italic from "./icons/Italic.vue";
import OrderedList from "./icons/OrderedList.vue";
import Paragraph from "./icons/Paragraph.vue";
import Redo from "./icons/Redo.vue";
import Strike from "./icons/Strike.vue";
import Underline from "./icons/Underline.vue";
import Undo from "./icons/Undo.vue";
export default {
  components: {
    Blockquote,
    Bold,
    BulletList,
    ClearNodes,
    Code,
    EditorContent,
    HardBreak,
    Heading1,
    Heading2,
    Heading3,
    HorizontalRule,
    Italic,
    OrderedList,
    Paragraph,
    Redo,
    Strike,
    Underline,
    Undo,
  },
  props: {
    modelValue: {
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    const editor = useEditor({
      content: props.modelValue,
      editorProps: {
        attributes: {
          class: "overflow-y-scroll",
        },
      },
      extensions: [StarterKit, UnderlineExtension],
      onUpdate: () => emit("update:modelValue", editor.value.getJSON()),
    });

    const hovers = ref([]);
    const menuItems = ref([]);

    const setMenuItems = () => {
      menuItems.value = [
        {
          command: () => {
            editor.value.chain().focus().toggleBold().run();
          },
          name: "bold",
          stateName: "bold",
        },
        {
          command: () => editor.value.chain().focus().toggleItalic().run(),
          name: "italic",
          stateName: "italic",
        },
        {
          command: () => editor.value.chain().focus().toggleUnderline().run(),
          name: "underline",
          stateName: "underline",
        },
        {
          command: () => editor.value.chain().focus().toggleStrike().run(),
          name: "strike",
          stateName: "strike",
        },
        {
          command: () => editor.value.chain().focus().toggleCode().run(),
          name: "code",
          stateName: "code",
        },
        {
          name: "divider-1",
        },
        {
          command: () =>
            editor.value.chain().focus().toggleHeading({ level: 1 }).run(),
          level: 1,
          name: "heading-1",
          stateName: "heading",
        },
        {
          command: () =>
            editor.value.chain().focus().toggleHeading({ level: 2 }).run(),
          level: 2,
          name: "heading-2",
          stateName: "heading",
        },
        {
          command: () =>
            editor.value.chain().focus().toggleHeading({ level: 3 }).run(),
          level: 3,
          name: "heading-3",
          stateName: "heading",
        },
        {
          command: () => editor.value.chain().focus().setParagraph().run(),
          name: "paragraph",
          stateName: "paragraph",
        },
        {
          command: () => editor.value.chain().focus().toggleBulletList().run(),
          name: "bullet-list",
          stateName: "bulletList",
        },
        {
          command: () => editor.value.chain().focus().toggleOrderedList().run(),
          name: "ordered-list",
          stateName: "orderedList",
        },
        {
          name: "divider-2",
        },
        {
          command: () => editor.value.chain().focus().toggleBlockquote().run(),
          name: "blockquote",
          stateName: "blockquote",
        },
        {
          command: () => editor.value.chain().focus().setHorizontalRule().run(),
          name: "horizontal-rule",
          stateName: "horizontalRule",
        },
        {
          name: "divider-3",
        },
        {
          command: () => editor.value.chain().focus().setHardBreak().run(),
          name: "hard-break",
          stateName: "hardBreak",
        },
        {
          command: () => editor.value.chain().focus().clearNodes().run(),
          name: "clear-nodes",
          stateName: "clearNodes",
        },
        {
          name: "divider-4",
        },
        {
          command: () => editor.value.commands.undo(),
          name: "undo",
          stateName: "undo",
        },
        {
          command: () => editor.value.commands.redo(),
          name: "redo",
          stateName: "redo",
        },
      ];
    };

    watch(
      () => editor.value,
      (value) => {
        if (value) setMenuItems();
      }
    );

    /*
    watch(
      () => props.modelValue,
      (value) => {
        const isSame = editor.value.getJSON() === value;

        if (isSame) return;
        editor.value.commands.setContent(value, false);
      }
    );
    */

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    return {
      company: computed(() => store.state.company.company),
      editor,
      hovers,
      menuItems,
    };
  },
};
</script>
